'use client';

import { NextIntlClientProvider } from 'next-intl';

export default function LocaleContext({
  locale,
  messages,
  children,
}: {
  children: React.ReactNode;
  messages: any;
  locale: string;
}) {
  return (
    <NextIntlClientProvider
      timeZone="Europe/Lisbon"
      messages={messages}
      locale={locale}
      onError={(e) => console.log('ERROR', e)}
    >
      {children}
    </NextIntlClientProvider>
  );
}
