'use client';
import { ReactNode, Suspense } from 'react';
import { AuthHandlerProvider } from '../context/authContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LoadingAnimation from '@/components/LoadingAnimation';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const recaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || 'NOT FOUND';

  return (
    <Suspense
      fallback={
        <div className="h-full w-full">
          <LoadingAnimation />
        </div>
      }
    >
      <AuthHandlerProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={recaptchaKey}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined,
          }}
        >
          {children}
        </GoogleReCaptchaProvider>
      </AuthHandlerProvider>
    </Suspense>
  );
};
